/* eslint-disable @typescript-eslint/naming-convention */
import { ThemeIcon, UnstyledButton, Group, Text } from '@mantine/core'
import { IconUsersGroup, IconUserStar, IconScanEye, IconMoneybag, IconDashboard, IconUserSearch } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'

interface MainLinkProps {
  icon: React.ReactNode
  color: string
  label: string

  href: string
}

function MainLink({ icon, color, label, href }: MainLinkProps) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isActive = pathname.includes(href)

  return (
    <UnstyledButton
      mb={10}
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,

        '&:hover': !isActive
          ? {
              color: theme.black,
              backgroundColor: theme.colors.gray[3],
            }
          : {},

        backgroundColor: isActive ? theme.colors.gray[6] : theme.white,
        color: isActive ? theme.white : theme.black,
      })}
      onClick={() => navigate(href)}
    >
      <Group>
        <ThemeIcon color={isActive ? 'white.0' : color} variant="outline">
          {icon}
        </ThemeIcon>

        <Text size="sm">{label}</Text>
      </Group>
    </UnstyledButton>
  )
}

const data = (t: (key: string) => string): MainLinkProps[] => [
  {
    icon: <IconDashboard size="1rem" />,
    color: 'custom-primary-color.0',
    label: t('sidebar.dashboard'),
    href: 'dashboard',
  },
  {
    icon: <IconUsersGroup size="1rem" />,
    color: 'custom-primary-color.0',
    label: t('sidebar.visitors'),
    href: 'visitors',
  },
  {
    icon: <IconUserStar size="1rem" />,
    color: 'custom-primary-color.0',
    label: t('sidebar.exhibitors'),
    href: 'exhibitors',
  },
  {
    icon: <IconUserSearch size="1rem" />,
    color: 'custom-primary-color.0',
    label: t('sidebar.users'),
    href: 'users',
  },
  {
    icon: <IconMoneybag size="1rem" />,
    color: 'custom-primary-color.0',
    label: t('sidebar.prices'),
    href: 'prices',
  },
  {
    icon: <IconScanEye size="1rem" />,
    color: 'custom-primary-color.0',
    label: t('sidebar.scanner'),
    href: 'scan',
  },
]

export function MainLinks() {
  const { t } = useTranslation()
  return (
    <>
      {data(t).map((link) => (
        <MainLink {...link} key={link.label} />
      ))}
    </>
  )
}
