import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'
import translationsFr from './locales/fr/trans.json'
import translationsEn from './locales/en/trans.json'
import dayjs from 'dayjs'
import 'dayjs/locale/fr-ca'

export enum Language {
  EN = 'en',
  FR = 'fr',
}

export enum NS {
  translation = 'translation',
  orders = 'orders',
}

void i18next
  .use(detector)
  .use(initReactI18next)
  .init({
    fallbackLng: Language.EN,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      [Language.FR]: {
        [NS.translation]: translationsFr,
      },
      [Language.EN]: {
        [NS.translation]: translationsEn,
      },
    },
  })
  .then(() => {
    if (i18next.language.indexOf('en') !== -1) {
      dayjs.locale('en')
    } else {
      dayjs.locale('fr-ca')
    }
  })

export default i18next
