import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Visitor, VisitorDto } from 'api/dto/visitor'
import axios, { AxiosResponse } from 'axios'
import { environment } from 'config/environment'

export function useVisitors(tradeshowId?: string, forExhibitors?: boolean) {
  return useQuery(
    [forExhibitors ? `my-relations-${tradeshowId}` : `visitors-${tradeshowId}`],
    async () => {
      const { data: visitors } = await axios.get<VisitorDto[]>(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/visitors`)

      return visitors.map((visitor) => new Visitor(visitor))
    },
    {
      enabled: tradeshowId != null,
      refetchInterval: 300000, // 5 minutes in milliseconds
    },
  )
}

export function useVisitor(visitorId?: string, tradeshowId?: string) {
  return useQuery(
    [`visitors-${tradeshowId}-${visitorId}`],
    async () => {
      const { data: visitor } = await axios.get<VisitorDto>(
        `${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/visitors/${visitorId}`,
      )

      return new Visitor(visitor)
    },
    { enabled: visitorId != null && tradeshowId != null },
  )
}

export function useAddVisitor(tradeshowId?: string) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (visitorDto: VisitorDto) => {
      const { data: newVisitorDtos } = await axios.post<VisitorDto[]>(
        `${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/visitors`,
        visitorDto,
      )
      if (newVisitorDtos.length > 1) {
        return newVisitorDtos.map((newVisitorDto) => new Visitor(newVisitorDto))
      }

      return new Visitor(newVisitorDtos[0])
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [`visitors-${tradeshowId}`] })
      void queryClient.invalidateQueries({ queryKey: [`users-${tradeshowId}`] })
    },
  })
}

export function useAddVisitorAdmin(tradeshowId?: string) {
  const queryClient = useQueryClient()
  return useMutation<Visitor, any, any, any>({
    mutationFn: async (visitorDto) => {
      const { data: newVisitorDto } = await axios.post(
        `${environment.restApi.resourceUrl.admin}${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/visitors`,
        visitorDto,
      )
      return new Visitor(newVisitorDto)
    },
    onMutate: async (newVisitorDto) => {
      await queryClient.cancelQueries([`visitors-${tradeshowId}`])

      const previousVisitors: Visitor[] = queryClient.getQueryData([`visitors-${tradeshowId}`]) || []

      const newVisitor = new Visitor({ ...newVisitorDto, paymentInfo: { amount: 0, prices: [] } })
      const addedVisitors = [...previousVisitors, newVisitor]

      queryClient.setQueryData([`visitors-${tradeshowId}`], addedVisitors)

      return { newVisitor }
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [`visitors-${tradeshowId}`] })
      void queryClient.invalidateQueries({ queryKey: [`users-${tradeshowId}`] })
    },
  })
}

export function useUpdateVisitor(tradeshowId: string) {
  const queryClient = useQueryClient()
  return useMutation<AxiosResponse<any>, any, any, any>({
    mutationFn: async (visitor: Partial<VisitorDto>) => {
      const { data } = await axios.put(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/visitors/${visitor.id}`, visitor)
      return data
    },
    onMutate: async (updatedVisitor) => {
      await queryClient.cancelQueries([`visitors-${tradeshowId}`])

      const previousVisitors: Visitor[] = queryClient.getQueryData([`visitors-${tradeshowId}`]) || []
      const previousVisitorIndex = previousVisitors?.findIndex((visitor) => visitor.id === updatedVisitor.id)
      const previousVisitor = { ...previousVisitors[previousVisitorIndex] }

      const updatedVisitors = [...previousVisitors]
      updatedVisitors[previousVisitorIndex] = new Visitor(updatedVisitor)

      queryClient.setQueryData([`visitors-${tradeshowId}`], updatedVisitors)

      return { previousVisitor, updatedVisitor }
    },
    onError: (err, newVisitor, context) => {
      queryClient.setQueryData([`visitors-${tradeshowId}`, context.updatedVisitor.id], context.previousVisitor)
    },
  })
}

export function useDeleteVisitor(tradeshowId: string) {
  const queryClient = useQueryClient()
  return useMutation<AxiosResponse<void>, any, any, any>({
    mutationFn: async (id) => axios.delete(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/visitors/${id}`),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [`visitors-${tradeshowId}`] })
      void queryClient.invalidateQueries({ queryKey: [`users-${tradeshowId}`] })
    },
  })
}

// /visitors/import
export function useImportVisitor(tradeshowId: string) {
  const queryClient = useQueryClient()
  return useMutation<AxiosResponse<void>, any, any, any>(
    ['importVisitors'],
    async (csv) => axios.post(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/visitors/import`, csv),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: ['visitors'] })
        void queryClient.invalidateQueries({ queryKey: [`users-${tradeshowId}`] })
      },
    },
  )
}

// /visitors/send-emails
export function useSendEmailsVisitor(tradeshowId: string) {
  return useMutation<AxiosResponse<void>, any, void, any>({
    mutationFn: async () => axios.post(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/visitors/send-emails`),
  })
}

// /visitors/:id/send-email
export function useSendEmailVisitor(tradeshowId: string) {
  return useMutation<AxiosResponse<void>, any, any, any>({
    mutationFn: async (id) => axios.post(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/visitors/${id}/send-email`),
  })
}
