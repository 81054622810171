import { PriceWithQuantity } from './price'

export interface StatsDto {
  badge_scanned: number
  exhibitor_admin_badge_printed: number
  exhibitor_kiosk_badge_printed: number
  visitor_admin_badge_printed: number
  visitor_kiosk_badge_printed: number
  prices: PriceWithQuantity[]
}
export class Stats {
  nbBadgeScanned: number
  nbTotalBadgePrint: number
  nbExhibitorAdminBadgePrint: number
  nbExhibitorKioskBadgePrint: number
  nbVisitorAdminBadgePrint: number
  nbVisitorKioskBadgePrint: number
  prices: PriceWithQuantity[]

  constructor(stats: StatsDto) {
    this.nbBadgeScanned = stats.badge_scanned
    this.nbTotalBadgePrint =
      stats.exhibitor_admin_badge_printed +
      stats.exhibitor_kiosk_badge_printed +
      stats.visitor_admin_badge_printed +
      stats.visitor_kiosk_badge_printed
    this.nbExhibitorAdminBadgePrint = stats.exhibitor_admin_badge_printed
    this.nbExhibitorKioskBadgePrint = stats.exhibitor_kiosk_badge_printed
    this.nbVisitorAdminBadgePrint = stats.visitor_admin_badge_printed
    this.nbVisitorKioskBadgePrint = stats.visitor_kiosk_badge_printed
    this.prices = stats.prices
  }
}
