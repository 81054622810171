export interface IUser {
  id: string
  tradeShowId: string
  firstName: string
  lastName: string
  companyName: string
  email: string
  code: string
  category?: string
  created?: string
  dietaryRestrictions?: string
  note?: string
  internalNotes?: string
}

export class User {
  id: string
  tradeShowId: string
  firstName: string
  lastName: string
  companyName: string
  email: string
  code: string
  category?: string
  created?: string
  dietaryRestrictions?: string
  note?: string
  internalNotes?: string

  constructor(user: IUser) {
    this.id = user.id
    this.tradeShowId = user.tradeShowId
    this.firstName = user.firstName
    this.lastName = user.lastName
    this.companyName = user.companyName
    this.email = user.email.toLowerCase().trim()
    this.code = user.code
    this.category = user.category
    this.created = user.created
    this.dietaryRestrictions = user.dietaryRestrictions
    this.note = user.note
    this.internalNotes = user.internalNotes
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  getRegistrationCodePath = (): string => {
    return `trade-shows/${this.tradeShowId}/codes/${this.code}.png`
  }
}
