import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Scanner from 'qr-scanner'

interface QrScannerProps {
  showScanner: boolean
  onScan: (result: { data: string }) => void
  hasCameraPermission: boolean
  setHasCameraPermission: (value: boolean) => void
}

export function QrScanner({ onScan, hasCameraPermission, setHasCameraPermission }: QrScannerProps) {
  const [hasStarted, setHasStarted] = useState(false)
  const [scanner, setScanner] = useState<Scanner | null>(null)

  const checkIfUserHasACamera = useCallback(async () => {
    const cameras = await navigator.mediaDevices.enumerateDevices()

    const hasCamera = cameras.some((camera) => camera.kind === 'videoinput')

    if (!hasCamera) {
      setHasCameraPermission(false)
    }
  }, [setHasCameraPermission])

  const checkCameraPermission = useCallback(async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setHasCameraPermission(false)
      return
    }

    // check current permission
    const permission = await navigator.permissions.query({ name: 'camera' as PermissionName })

    if (permission.state === 'granted') {
      setHasCameraPermission(true)
      return true
    } else if (permission.state === 'denied') {
      setHasCameraPermission(false)
      return
    }

    const result = await navigator.mediaDevices.getUserMedia({ video: true })

    if (result) {
      setHasCameraPermission(true)
      return
    }
  }, [setHasCameraPermission])

  const videoRef = useCallback(
    (node: HTMLVideoElement | null) => {
      if (node !== null && !scanner) {
        setScanner(
          new Scanner(node, onScan, {
            highlightScanRegion: true,
            maxScansPerSecond: 10,
            highlightCodeOutline: true,
            preferredCamera: 'environment',
          }),
        )
      }
    },
    [onScan, scanner],
  )

  useEffect(() => {
    void checkIfUserHasACamera()
    void checkCameraPermission()
  }, [checkCameraPermission, checkIfUserHasACamera])

  useEffect(() => {
    if (scanner && !hasStarted) {
      void scanner.start()
      setHasStarted(true)
    }
  }, [hasStarted, scanner])

  useEffect(() => {
    return () => {
      if (scanner) {
        void scanner.stop()
      }
    }
  }, [scanner])

  return hasCameraPermission && <video style={{ objectFit: 'cover' }} width="100%" height="100%" ref={(node) => videoRef(node)} />
}
