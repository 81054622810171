/* eslint-disable @typescript-eslint/naming-convention */
import { Text, Box, Image, ActionIcon } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Logo } from 'components/logo'
import { useMediaQuery } from '@mantine/hooks'
import { useTradeshow } from 'api/query/tradeshow'
import { Loading } from 'components/loading/loading'

export const RegisterSuccess = () => {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation()
  const { tradeshowId } = useParams()
  const { data: tradeshow, isSuccess, isLoading } = useTradeshow(tradeshowId)
  const isSmallDevice = useMediaQuery('(max-width: 864px)')
  const currentLanguage = language.split('-')[0]

  if (isLoading) {
    return <Loading />
  }

  if (!isSuccess) {
    return null
  }

  return (
    <Box
      display="flex"
      w="100%"
      sx={{ height: '100vh', flexDirection: 'column', alignItems: 'center', width: isSmallDevice ? '100%' : '80%' }}
    >
      <Box p={20} display="flex" sx={{ width: isSmallDevice ? '100%' : '80%', justifyContent: 'space-between', alignItems: 'center' }}>
        <Logo width={150} />
        <ActionIcon variant="default" onClick={() => changeLanguage(currentLanguage === 'fr' ? 'en' : 'fr')} size={30}>
          <Text size="xs">{currentLanguage === 'fr' ? 'EN' : 'FR'}</Text>
        </ActionIcon>
      </Box>

      <Box mb={50} display="flex" sx={{ flexDirection: 'column' }}>
        <Text color="green" weight={700} align="center" size="40px">
          {t('register.success')}
        </Text>
        <Text align="center" size="25px">
          {t('register.thank-you', { replace: { tradeshowName: currentLanguage === 'fr' ? tradeshow.nameFr : tradeshow.nameEn } })}
        </Text>
        <Text align="center" size="23px">
          {t('register.ticket')}
        </Text>
      </Box>

      {tradeshow?.config.sponsorLogo && (
        <>
          <Box mb={20} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Text align="center" size="20px">
              {t('register.our-sponsors')}
            </Text>
          </Box>

          <Box mb={40} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Image maw={600} src={tradeshow?.config.sponsorLogo} />
          </Box>
        </>
      )}
    </Box>
  )
}
