import { Button, Box, ActionIcon, Affix, Transition, Text, Breadcrumbs, Anchor } from '@mantine/core'
import { useMediaQuery, useWindowScroll } from '@mantine/hooks'
import { IconScan, IconEdit, IconTrash, IconArrowUp } from '@tabler/icons-react'
import { ColumnDef } from '@tanstack/react-table'
import { Relation } from 'api/dto/relation'
import { Visitor } from 'api/dto/visitor'
import { useGetExhibitor, useListExhibitorRelations } from 'api/query/exhibitor'
import { useTradeshow } from 'api/query/tradeshow'
import { Banner } from 'components/banner/banner'
import { EventEnded } from 'components/event-end/event-ended'
import { Header } from 'components/header/header'
import { Loading } from 'components/loading/loading'
import { AddMyRelation } from 'components/modal/exhibitor/add.my-relation'
import { DeleteMyRelation } from 'components/modal/exhibitor/delete.my-relation'
import { EditMyRelation } from 'components/modal/exhibitor/edit.my-relation'
import { Table } from 'components/table'
import { VisitorTileList } from 'components/tile/visitor-tile-list'
import dayjs from 'dayjs'
import { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

export function Relations({
  exhibitorId,
  tradeshowId,
  isFromAdmin,
}: {
  exhibitorId?: string
  tradeshowId?: string
  isFromAdmin?: boolean
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const outletContext = useOutletContext<any>()
  const { data: tradeshow, isLoading: isLoadingTradeshow, isSuccess } = useTradeshow(tradeshowId)
  const { data: exhibitor } = useGetExhibitor(tradeshowId, exhibitorId)
  const { data: relations, isLoading } = useListExhibitorRelations(exhibitorId, tradeshowId)
  const [showScanner, setShowScanner] = useState(false)
  const [editRelation, setEditRelation] = useState<Relation | undefined>(undefined)
  const [deleteRelation, setDeleteRelation] = useState<Relation | undefined>()
  const isSmallDevice = useMediaQuery('(max-width: 864px)')
  const [scroll, scrollTo] = useWindowScroll()

  const noExhibitor = !exhibitorId || !tradeshowId
  const eventScheduledForDeletion = tradeshow?.deletionDate && dayjs(tradeshow.deletionDate).isBefore(dayjs())

  useEffect(() => {
    if (!outletContext) {
      return
    }

    outletContext?.setMarginTop?.(50)
    return () => {
      outletContext?.setMarginTop?.(0)
    }
  }, [outletContext])

  const checkCameraPermission = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      return false
    }

    // check current permission
    const permission = await navigator.permissions.query({ name: 'camera' as PermissionName })

    if (permission.state === 'granted') {
      return true
    } else if (permission.state === 'prompt') {
      alert('Please allow camera permission to use this feature')
    } else if (permission.state === 'denied') {
      alert('Please allow camera permission to use this feature')
      return
    }

    const result = await navigator.mediaDevices.getUserMedia({ video: true })

    if (result) {
      return true
    }
  }

  const actionButtons = () => [
    <Button
      color="custom-primary-color.0"
      h={44}
      leftIcon={<IconScan size={20} />}
      variant="filled"
      size="sm"
      onClick={async () => {
        const hasPermission = await checkCameraPermission()

        if (!hasPermission) {
          return
        }

        setShowScanner(true)
      }}
    >
      {t('visitor.scan')}
    </Button>,
  ]

  const columns: ColumnDef<Relation>[] = [
    {
      header: t('visitor.created-at'),
      id: 'created',
      accessorKey: 'created',
      cell: (props) => {
        return <Text size="10px">{dayjs(props.getValue() as string).format('YYYY-MM-DD HH:mm')}</Text>
      },
    },
    {
      id: 'fullName',
      header: t('visitor.fullName'),
      accessorKey: 'fullName',
    },
    {
      id: 'email',
      header: t('visitor.email'),
      accessorKey: 'email',
      cell: (props) => (props.getValue() as string).toLocaleLowerCase(),
    },
    {
      id: 'company',
      accessorKey: 'companyName',
      header: t('visitor.company'),
    },
    {
      id: 'company-phone',
      accessorKey: 'companyPhone',
      header: t('visitor.company-phone'),
    },
    {
      id: 'job',
      accessorKey: 'jobTitle',
      header: t('visitor.job-title'),
    },

    {
      id: 'note',
      accessorKey: 'note',
      header: t('visitor.note'),
    },

    {
      id: 'actions',
      header: t('visitor.actions'),
      cell: (props) => {
        const relation = props.row.original
        return (
          <Box display="flex">
            <ActionIcon variant="outline" size="md" color="green" onClick={() => setEditRelation({ id: relation.id, note: relation.note })}>
              <IconEdit size="20px" />
            </ActionIcon>
            <ActionIcon ml={10} variant="outline" size="md" color="red" onClick={() => setDeleteRelation(props.row.original)}>
              <IconTrash size="20px" />
            </ActionIcon>
          </Box>
        )
      },
    },
  ]

  if ((isLoading && exhibitorId) || isLoadingTradeshow || !isSuccess) {
    return <Loading height="calc(100vh - 235px)" size="md" />
  }

  if (isSmallDevice) {
    return (
      <Box bg="#fcfcfc" h="100vh">
        {!isFromAdmin && <Header />}
        <Banner isFromAdmin={isFromAdmin} deletionDate={tradeshow.deletionDate} />
        <Box mt={30} display="flex" sx={{ alignItems: 'center', flexDirection: 'column' }}>
          {!isFromAdmin && (
            <Box w="90%">
              <Text mb={30} size="xl">
                {language == 'fr' ? tradeshow?.nameFr : tradeshow?.nameEn}
              </Text>
            </Box>
          )}

          {eventScheduledForDeletion || noExhibitor ? (
            <EventEnded />
          ) : (
            <>
              <VisitorTileList
                tradeshowId={tradeshowId}
                exhibitorId={exhibitorId}
                visitors={relations || []}
                actionIcons={[
                  <Button
                    w="100%"
                    leftIcon={<IconScan width={20} />}
                    size="md"
                    variant="filled"
                    color="custom-primary-color.0"
                    onClick={() => setShowScanner(true)}
                  >
                    {t('common.scan')}
                  </Button>,
                ]}
                exportList={{ filename: `my-relations-${dayjs().format('YYYY-MM-DD-HH-mm')}` }}
              />
              {showScanner && <AddMyRelation opened={showScanner} onClose={() => setShowScanner(false)} />}
              <Affix position={{ bottom: 20, right: 20 }}>
                <Transition transition="slide-up" mounted={scroll.y > 0}>
                  {(transitionStyles) => (
                    <ActionIcon
                      style={transitionStyles}
                      size={40}
                      variant="filled"
                      color="custom-primary-color.0"
                      onClick={() => scrollTo({ y: 0 })}
                      radius="100%"
                    >
                      <IconArrowUp size={20} />
                    </ActionIcon>
                  )}
                </Transition>
              </Affix>
            </>
          )}
        </Box>
      </Box>
    )
  }

  const items = [
    { title: t('sidebar.exhibitors').toUpperCase(), href: `/#/tradeshows/${tradeshowId}/exhibitors` },
    { title: `${exhibitor?.fullName} RELATIONS`, href: `/#/tradeshows/${tradeshowId}/exhibitors/${exhibitorId}/relations` },
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ))

  return (
    <Box bg="#fcfcfc" h="100vh">
      {!isFromAdmin && <Header />}
      <Banner isFromAdmin={isFromAdmin} deletionDate={tradeshow.deletionDate} />
      {isFromAdmin && (
        <Breadcrumbs ml={5} separator="→" mt="sm">
          {items}
        </Breadcrumbs>
      )}
      <Box ml="auto" mr="auto" mt="3%" w={isFromAdmin ? '100%' : '90%'} sx={!isFromAdmin ? { padding: '20px 30px' } : {}}>
        {!isFromAdmin && (
          <Text mb={30} size="xl">
            {language == 'fr' ? tradeshow.nameFr : tradeshow.nameEn}
          </Text>
        )}

        {eventScheduledForDeletion || noExhibitor ? (
          <EventEnded />
        ) : (
          <>
            <Table<Relation>
              hiddenColumns={{ firstName: false, lastName: false }}
              defaultSort={{ id: 'created', desc: true }}
              data={relations || []}
              columns={columns}
              actionButtons={isFromAdmin ? actionButtons : () => []}
              exportTable={{ filename: `my-relations-${dayjs().format('YYYY-MM-DD-HH-mm')}` }}
            />
            {showScanner && <AddMyRelation opened={showScanner} onClose={() => setShowScanner(false)} />}
            <EditMyRelation
              tradeShowId={tradeshowId}
              exhibitorId={exhibitorId}
              opened={!!editRelation}
              relation={editRelation}
              onClose={() => setEditRelation(undefined)}
            />
            <DeleteMyRelation opened={!!deleteRelation} onClose={() => setDeleteRelation(undefined)} relation={deleteRelation} />
          </>
        )}
      </Box>
    </Box>
  )
}
