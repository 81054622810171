import { ActionIcon, Box, Text } from '@mantine/core'
import { ColumnDef } from '@tanstack/react-table'
import { Loading } from 'components/loading/loading'
import { Table } from 'components/table'
import { useTranslation } from 'react-i18next'
import { IconPrinter } from '@tabler/icons-react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useUsers } from 'api/query/user'
import { User } from 'api/entities/user'
import { saveBase64ToFile } from 'utils/base64'
import dayjs from 'dayjs'
import { showNotification } from '@mantine/notifications'
import { getBadge, PrintSource } from 'utils/badge'

export function Users() {
  const { t } = useTranslation()
  const { tradeshowId } = useParams()
  const { data: users, isLoading: isLoadingUsers } = useUsers(tradeshowId)

  const [isPrinting, setIsPrinting] = useState<string | undefined>()

  const columns: ColumnDef<User>[] = [
    {
      id: 'print',
      cell: (props) => (
        <Box>
          <ActionIcon
            variant="outline"
            size="md"
            loading={isPrinting === props.row.original.id}
            onClick={() => {
              if (!tradeshowId) {
                return
              }

              setIsPrinting(props.row.original.id)
              setTimeout(async () => {
                showNotification({
                  message: 'Downloading badge... Please wait',
                  color: 'green',
                  title: 'Downloading...',
                  loading: true,
                  autoClose: 5000,
                })

                let badge: string | null
                try {
                  badge = await getBadge(tradeshowId, props.row.original.code, PrintSource.ADMIN)
                } catch (error) {
                  setIsPrinting(undefined)
                  return
                }

                if (!badge) {
                  setIsPrinting(undefined)
                  return
                }

                saveBase64ToFile(badge, `exhibitor-badge-${props.row.original.id}.png`)
                setIsPrinting(undefined)
              }, 400)
            }}
          >
            <IconPrinter size="20px" />
          </ActionIcon>
        </Box>
      ),
    },
    {
      id: 'created',
      header: t('exhibitor.created-at'),
      accessorKey: 'created',
      enableColumnFilter: false,
      cell: (props) => {
        return <Text size="10px">{dayjs(props.getValue() as string).format('YYYY-MM-DD HH:mm')}</Text>
      },
      minSize: 100,
    },
    {
      id: 'company-name',
      header: t('exhibitor.company-name'),
      accessorKey: 'companyName',
      minSize: 100,
    },
    {
      id: 'fullName',
      header: t('exhibitor.fullName'),
      accessorKey: 'fullName',
      minSize: 100,
    },
    {
      id: 'category',
      accessorKey: 'category',
      header: t('exhibitor.category'),
      minSize: 100,
    },
    {
      id: 'email',
      header: t('exhibitor.email'),
      accessorKey: 'email',
      enableColumnFilter: false,
      cell: (props) => (props.getValue() as string).toLocaleLowerCase(),
      minSize: 100,
    },
  ]

  if (isLoadingUsers) {
    return <Loading height="calc(100vh - 235px)" size="md" />
  }

  return (
    <Table<User>
      defaultSort={{
        id: 'created',
        desc: true,
      }}
      data={users}
      columns={columns}
    />
  )
}
