import { Box, Button, Grid, Skeleton, Text } from '@mantine/core'
import { ColumnDef } from '@tanstack/react-table'
import { PriceWithQuantity } from 'api/dto/price'
import { useGetTradeshowStats } from 'api/query/stats'
import { Loading } from 'components/loading/loading'
import { Table } from 'components/table'
import { Widget } from 'components/widget/widget'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { DateTime } from 'utils/date-time'

export function Dashboard() {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { tradeshowId } = useParams()
  const { data: stats, isSuccess, isLoading } = useGetTradeshowStats(tradeshowId)

  const columns: ColumnDef<PriceWithQuantity>[] = [
    {
      id: 'name',
      header: t('price.name'),
      cell: (props) => {
        const row = props.row.original
        if (language === 'en') {
          return row.nameEn
        }

        return row.nameFr
      },
    },
    {
      id: 'quantity',
      accessorKey: 'quantity',
      header: t('price.quantity'),
    },
  ]

  return (
    <>
      <Grid>
        <Grid.Col span={6}>
          <Widget>
            <Box>
              <Text color="custom-primary-color.0" size="lg" fw={700}>
                {t('stats.nbTotalBadgePrint')}
              </Text>
              {isLoading && <Skeleton mt={30} height={14} radius="xl" />}
              {!isLoading && (
                <Text size="xl" mt={10}>
                  {stats?.nbTotalBadgePrint || 0}
                </Text>
              )}
            </Box>
          </Widget>
        </Grid.Col>
        <Grid.Col span={6}>
          <Widget>
            <Box>
              <Text color="custom-primary-color.0" size="lg" fw={700}>
                {t('stats.nbBadgeScanned')}
              </Text>
              {isLoading && <Skeleton mt={30} height={14} radius="xl" />}
              {!isLoading && (
                <Text size="xl" mt={10}>
                  {stats?.nbBadgeScanned || 0}
                </Text>
              )}
            </Box>
          </Widget>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={3}>
          <Widget>
            <Box>
              <Text color="custom-primary-color.0" size="lg" fw={700}>
                {t('stats.nbExhibitorAdminBadgePrint')}
              </Text>
              {isLoading && <Skeleton mt={30} height={14} radius="xl" />}
              {!isLoading && (
                <Text size="xl" mt={10}>
                  {stats?.nbExhibitorAdminBadgePrint || 0}
                </Text>
              )}
            </Box>
          </Widget>
        </Grid.Col>
        <Grid.Col span={3}>
          <Widget>
            <Box>
              <Text color="custom-primary-color.0" size="lg" fw={700}>
                {t('stats.nbExhibitorKioskBadgePrint')}
              </Text>
              {isLoading && <Skeleton mt={30} height={14} radius="xl" />}
              {!isLoading && (
                <Text size="xl" mt={10}>
                  {stats?.nbExhibitorKioskBadgePrint || 0}
                </Text>
              )}
            </Box>
          </Widget>
        </Grid.Col>
        <Grid.Col span={3}>
          <Widget>
            <Box>
              <Text color="custom-primary-color.0" size="lg" fw={700}>
                {t('stats.nbVisitorAdminBadgePrint')}
              </Text>
              {isLoading && <Skeleton mt={30} height={14} radius="xl" />}
              {!isLoading && (
                <Text size="xl" mt={10}>
                  {stats?.nbVisitorAdminBadgePrint || 0}
                </Text>
              )}
            </Box>
          </Widget>
        </Grid.Col>
        <Grid.Col span={3}>
          <Widget>
            <Box>
              <Text color="custom-primary-color.0" size="lg" fw={700}>
                {t('stats.nbVisitorKioskBadgePrint')}
              </Text>
              {isLoading && <Skeleton mt={30} height={14} radius="xl" />}
              {!isLoading && (
                <Text size="xl" mt={10}>
                  {stats?.nbVisitorKioskBadgePrint || 0}
                </Text>
              )}
            </Box>
          </Widget>
        </Grid.Col>
      </Grid>
      <Box mt={50}>
        {isLoading && <Loading size="xl" height="30vh" />}
        {isSuccess && (
          <>
            <Table<PriceWithQuantity>
              title={
                <Text m={20} fw={700} size="lg" color="custom-primary-color.0">
                  {t('stats.priceStats')}
                </Text>
              }
              defaultSort={{
                id: 'quantity',
                desc: true,
              }}
              columns={columns}
              data={stats.prices || []}
              searchable={false}
              hidePagination
              exportTable={{ filename: `stats-prices-${DateTime.now().date.format('YYYY-MM-DD')}` }}
            />
          </>
        )}
      </Box>
    </>
  )
}
