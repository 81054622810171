import { ActionIcon, Box, Button, LoadingOverlay, Menu, Text } from '@mantine/core'
import { IconEdit, IconEyeEdit, IconMail, IconPlus, IconPrinter, IconSettings, IconTrash, IconUsers } from '@tabler/icons-react'
import { ColumnDef, Row } from '@tanstack/react-table'
import { Exhibitor } from 'api/dto/exhibitor'
import { useExhibitors } from 'api/query/exhibitor'
import axios from 'config/axios'
import { Loading } from 'components/loading/loading'
import { AddExhibitor } from 'components/modal/exhibitor/add.exhibitor'
import { EditExhibitor } from 'components/modal/exhibitor/edit.exhibitor'
import { UploadModal } from 'components/modal/upload'
import { Table } from 'components/table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { environment } from 'config/environment'
import { useQueryClient } from '@tanstack/react-query'
import { DeleteExhibitor } from 'components/modal/exhibitor/delete.exhibitor'
import dayjs from 'dayjs'
import { useBatchPrintExhibitorsBadge, usePrintExhibitorBadge } from 'utils/badge'
import { showNotification } from '@mantine/notifications'
import { download, saveBase64ToFile } from 'utils/base64'
import { SendEmailsExhibitor } from 'components/modal/exhibitor/send-emails.exhibitor'
import { SendEmailExhibitor } from 'components/modal/exhibitor/send-email.exhibitor'

export function Exhibitors() {
  const { t } = useTranslation()
  const { tradeshowId } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { data: exhibitors, isLoading } = useExhibitors(tradeshowId)
  const { mutateAsync: printExhibitorBadge } = usePrintExhibitorBadge(tradeshowId)
  const { mutateAsync: batchPrintExhibitorBadge } = useBatchPrintExhibitorsBadge(tradeshowId)
  const [editExhibitor, setEditExhibitor] = useState<Exhibitor | undefined>()
  const [deleteExhibitor, setDeleteExhibitor] = useState<Exhibitor | undefined>()
  const [sendExhibitorEmail, setSendExhibitorEmail] = useState<Exhibitor | undefined>()
  const [addExhibitorModalOpened, setAddExhibitorModalOpened] = useState(false)
  const [importExhibitorsOpened, setImportExhibitorsOpened] = useState(false)
  const [sendEmailsOpened, setSendEmailsOpened] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [isBatchPrinting, setIsBatchPrinting] = useState(false)

  const actionButtons = (rows: Row<Exhibitor>[]) => [
    <Button
      h={44}
      color="custom-primary-color.0"
      leftIcon={<IconPlus size={17} />}
      size="sm"
      onClick={() => setAddExhibitorModalOpened(true)}
    >
      {t('exhibitor.add')}
    </Button>,
    <Button
      ml={5}
      color="custom-primary-color.0"
      h={44}
      disabled={isBatchPrinting}
      leftIcon={<IconPrinter size={17} />}
      size="sm"
      onClick={async () => {
        setIsBatchPrinting(true)
        showNotification({
          message: 'Downloading badges... Please wait',
          color: 'green',
          title: 'Downloading...',
          loading: true,
          autoClose: 5000,
        })

        try {
          const url = await batchPrintExhibitorBadge(rows.map((r) => r.original.id))
          download(`exhibitors-badges-${dayjs().format('YYYY-MM-DD-HH-mm')}`, url)
        } catch (error) {
          // nothing
        }

        setIsBatchPrinting(false)
      }}
    >
      {t('exhibitor.print-all')}
    </Button>,
  ]

  const columns: ColumnDef<Exhibitor>[] = [
    {
      id: 'print',
      cell: (props) => (
        <Box>
          <ActionIcon
            variant="outline"
            size="md"
            onClick={() => {
              setIsPrinting(true)
              setTimeout(async () => {
                showNotification({
                  message: 'Downloading badge... Please wait',
                  color: 'green',
                  title: 'Downloading...',
                  loading: true,
                  autoClose: 5000,
                })

                let badge: string | null
                try {
                  badge = await printExhibitorBadge(props.row.original.id)
                } catch (error) {
                  setIsPrinting(false)
                  return
                }

                saveBase64ToFile(badge, `exhibitor-badge-${props.row.original.id}.png`)
                setIsPrinting(false)
              }, 400)
            }}
          >
            <IconPrinter size="20px" />
          </ActionIcon>
        </Box>
      ),
    },
    {
      id: 'created',
      header: t('exhibitor.created-at'),
      accessorKey: 'created',
      enableColumnFilter: false,
      cell: (props) => {
        return <Text size="10px">{dayjs(props.getValue() as string).format('YYYY-MM-DD HH:mm')}</Text>
      },
      minSize: 100,
    },
    {
      id: 'company-name',
      header: t('exhibitor.company-name'),
      accessorKey: 'companyName',
      minSize: 100,
    },
    {
      id: 'fullName',
      header: t('exhibitor.fullName'),
      accessorKey: 'fullName',
      minSize: 100,
    },
    {
      id: 'category',
      accessorKey: 'category',
      header: t('exhibitor.category'),
      minSize: 100,
    },
    {
      id: 'email',
      header: t('exhibitor.email'),
      accessorKey: 'email',
      enableColumnFilter: false,
      cell: (props) => (props.getValue() as string).toLocaleLowerCase(),
      minSize: 100,
    },
    {
      id: 'internalNotes',
      header: t('exhibitor.internal-notes'),
      accessorKey: 'internalNotes',
      enableColumnFilter: false,
      minSize: 100,
      maxSize: 200,
    },
    {
      id: 'actions',
      header: t('exhibitor.actions'),
      enableColumnFilter: false,
      cell: (props) => (
        <Box display="flex">
          <ActionIcon
            variant="outline"
            size="md"
            color="blue"
            onClick={() => navigate(`/tradeshows/${tradeshowId}/exhibitors/${props.row.original.id}/relations`)}
          >
            <IconEyeEdit size={20} />
          </ActionIcon>

          <ActionIcon ml={10} variant="outline" size="md" onClick={() => setEditExhibitor(props.row.original)}>
            <IconEdit size={20} />
          </ActionIcon>

          <ActionIcon ml={10} variant="outline" color="red" size="md" onClick={() => setDeleteExhibitor(props.row.original)}>
            <IconTrash size={20} />
          </ActionIcon>

          <ActionIcon ml={10} variant="outline" color="green" size="md" onClick={() => setSendExhibitorEmail(props.row.original)}>
            <IconMail size={20} />
          </ActionIcon>
        </Box>
      ),
    },
  ]

  if (isLoading) {
    return <Loading height="calc(100vh - 235px)" size="md" />
  }

  return (
    <>
      <LoadingOverlay visible={isPrinting} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      <Box sx={{ position: 'absolute', top: 30, right: 30 }}>
        <Menu withArrow>
          <Menu.Target>
            <Button leftIcon={<IconSettings size={25} />} size="md" variant="outline" color="custom-primary-color.0">
              {t('exhibitor.settings')}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>{t('exhibitor.title')}</Menu.Label>
            <Menu.Item icon={<IconUsers size={17} />} onClick={() => setImportExhibitorsOpened(true)}>
              {t('exhibitor.import')}
            </Menu.Item>

            <Menu.Item icon={<IconMail size={17} />} onClick={() => setSendEmailsOpened(true)}>
              {t('exhibitor.send-emails')}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Box>

      <Table<Exhibitor>
        data={exhibitors || []}
        columns={columns}
        defaultSort={{
          id: 'created',
          desc: true,
        }}
        actionButtons={actionButtons}
        exportTable={{ filename: `exhibitors-${dayjs().format('YYYY-MM-DD-HH-mm')}` }}
      />
      <AddExhibitor opened={addExhibitorModalOpened} onClose={() => setAddExhibitorModalOpened(false)} />
      <EditExhibitor opened={!!editExhibitor} onClose={() => setEditExhibitor(undefined)} exhibitor={editExhibitor} />
      <DeleteExhibitor opened={!!deleteExhibitor} onClose={() => setDeleteExhibitor(undefined)} exhibitor={deleteExhibitor} />
      <SendEmailExhibitor opened={!!sendExhibitorEmail} onClose={() => setSendExhibitorEmail(undefined)} exhibitor={sendExhibitorEmail} />
      <SendEmailsExhibitor opened={sendEmailsOpened} onClose={() => setSendEmailsOpened(false)} />
      <UploadModal
        opened={importExhibitorsOpened}
        onClose={() => setImportExhibitorsOpened(false)}
        onUpload={(file, uploadProgress) => {
          const reader = new FileReader()
          reader.addEventListener('load', async (e) => {
            const result = e.target?.result

            await axios.post(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/exhibitors/import`, result, {
              onUploadProgress: uploadProgress,
            })

            await queryClient.invalidateQueries({ queryKey: [`exhibitors-${tradeshowId}`] })
          })
          reader.readAsText(file)
        }}
      />
    </>
  )
}
