import { useQuery } from '@tanstack/react-query'
import { UserDto } from 'api/dto/user'
import { Visitor, VisitorDto } from 'api/dto/visitor'
import { User } from 'api/entities/user'
import axios from 'axios'
import { environment } from 'config/environment'
import { useCallback } from 'react'

export function useUser(userId?: string, tradeshowId?: string) {
  return useQuery(
    [`users-${tradeshowId}-${userId}`],
    async () => {
      const { data: visitor } = await axios.get<VisitorDto>(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/users/${userId}`)

      return new Visitor(visitor)
    },
    { enabled: userId != null && tradeshowId != null },
  )
}

export function useUsers(tradeshowId?: string) {
  return useQuery(
    [`users-${tradeshowId}`],
    async () => {
      const { data } = await axios.get<UserDto[]>(`${environment.restApi.resourceUrl.tradeshows}/${tradeshowId}/users`)
      return data
    },
    {
      enabled: tradeshowId != null,
      initialData: [],
      select: useCallback((response: UserDto[]) => response.map((user) => new User(user)), []),
    },
  )
}
