/* eslint-disable @typescript-eslint/unbound-method */
import { ActionIcon, Box, Group, Header as MantineHeader, Menu, Text, useMantineColorScheme } from '@mantine/core'
import { IconSun, IconMoonStars } from '@tabler/icons-react'
import { Logo } from 'components/logo'
import { User } from 'components/user/user'
import dayjs from 'dayjs'
import { changeLanguage } from 'i18next'
import { useTranslation } from 'react-i18next'

export function Header() {
  const {
    i18n: { language },
  } = useTranslation()

  const currentLanguage = language.split('-')[0]

  return (
    <MantineHeader height={60}>
      <Group sx={{ height: '100%' }} px={20} position="apart">
        <Logo />
        <Box display="flex">
          <ActionIcon
            ml={10}
            variant="default"
            onClick={async () => {
              dayjs.locale(currentLanguage === 'fr' ? 'en' : 'fr-ca')
              await changeLanguage(currentLanguage === 'fr' ? 'en' : 'fr')
            }}
            size={30}
          >
            <Text size="xs">{currentLanguage === 'fr' ? 'EN' : 'FR'}</Text>
          </ActionIcon>

          <User ml={20} size="md" radius="xl" />
        </Box>
      </Group>
    </MantineHeader>
  )
}
