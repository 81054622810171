import { TextInput, Group, Button, Text, Box, Image } from '@mantine/core'
import { UseFormReturnType, useForm } from '@mantine/form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { validatePassword } from 'utils/password'
import { Logo } from 'components/logo'
import { IconPassword } from '@tabler/icons-react'
import { useMediaQuery } from '@mantine/hooks'

interface FormValues {
  code: string
  password: string
  confirmPassword: string
}

export const ResetPassword = () => {
  const { resetPassword } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const isSmallDevice = useMediaQuery('(max-width: 864px)')

  const form = useForm({
    initialValues: {
      code: '',
      password: '',
      confirmPassword: '',
    },
    validate: {
      password: (value) =>
        validatePassword(value) ? null : 'The password must contain one uppercase, one lowercase, a number and at least 8 characters',
      // eslint-disable-next-line quotes
      confirmPassword: (value, values) => (value === values?.password ? null : "Password doesn't match"),
    },
  })

  async function onSubmit(values: FormValues) {
    setIsLoading(true)
    await resetPassword(values.code, values.password)
    setIsLoading(false)
  }

  if (isSmallDevice) {
    return (
      <Box w="100%" display="flex" sx={{ flexDirection: 'column' }}>
        <Box pt={30} pl={30}>
          <Logo width={150} />
        </Box>
        <Box
          pl={30}
          pr={30}
          mt={30}
          mb={50}
          h={240}
          sx={(theme) => ({
            background: theme.colors['custom-primary-color'][0],
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            color: theme.white,
          })}
        >
          <Text align="center" weight={500} size="40px">
            {t('authentication.reset-password')}
          </Text>
          <Text align="center" weight={400} size="xl">
            {t('authentication.input-code')}
          </Text>
        </Box>
        <ResetPasswordForm form={form} onSubmit={onSubmit} isLoading={isLoading} isSmall />
      </Box>
    )
  }

  return (
    <Box display="flex" w="100%">
      <Box
        w={400}
        bg="custom-primary-color.0"
        p={30}
        pos="relative"
        mx="auto"
        display="flex"
        sx={{ borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px', color: 'white', overflow: 'hidden', flexDirection: 'column' }}
      >
        <Box sx={{ position: 'absolute', top: 5, left: '-100px', right: 0, bottom: 0, height: '100%', minWidth: '1264px' }}>
          <Image src="https://cdn.expovisio.com/full-banner.png" alt="additional-img" />
        </Box>
        <Logo />
        <Box w="100%" pt={150} display="flex" sx={{ flex: 1, alignItems: 'center', justifyContent: 'flex-end', flexFlow: 'column' }}>
          <Button
            variant="filled"
            color="dark.4"
            radius="md"
            onClick={() => navigate('/session/login')}
            sx={{
              fontSize: '18px',
              lineHeight: '22px',
              color: 'white',
              fontWeight: 400,
              width: '216px',
              height: '50px',
              borderColor: 'white',
            }}
          >
            {t('authentication.sign-in')}
          </Button>
        </Box>
      </Box>
      <Box p={30} display="flex" sx={{ flex: 1, justifyContent: 'center', flexFlow: 'column', zIndex: 100 }}>
        <Box mb={30} sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Text align="center" size="xl">
            {t('authentication.reset-password')}
          </Text>
        </Box>

        <Box mb={50} sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Text align="center" size="md">
            {t('authentication.input-code')}
          </Text>
        </Box>

        <ResetPasswordForm form={form} onSubmit={onSubmit} isLoading={isLoading} />
      </Box>
    </Box>
  )
}

interface ResetPasswordProps {
  form: UseFormReturnType<
    {
      code: string
      password: string
      confirmPassword: string
    },
    (values: { code: string; password: string; confirmPassword: string }) => {
      code: string
      password: string
      confirmPassword: string
    }
  >
  isLoading: boolean
  onSubmit: (values: FormValues) => Promise<void>
  isSmall?: boolean
}

function ResetPasswordForm({ form, isLoading, onSubmit, isSmall }: ResetPasswordProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Box display="flex" sx={{ justifyContent: 'center' }}>
      <Box w="70%" maw={650}>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Box mb={10}>
            <TextInput size="md" required type="number" placeholder={t('authentication.code')} {...form.getInputProps('code')} />
          </Box>
          <Box mb={10}>
            <TextInput
              size="md"
              type="password"
              icon={<IconPassword width={15} height={15} stroke="#cecece" />}
              required
              placeholder={t('authentication.password') || ''}
              {...form.getInputProps('password')}
            />
          </Box>

          <Box mb={10}>
            <TextInput
              size="md"
              type="password"
              icon={<IconPassword width={15} height={15} stroke="#cecece" />}
              required
              placeholder={t('authentication.confirm-password') || ''}
              {...form.getInputProps('confirmPassword')}
            />
          </Box>

          <Group position="center" sx={{ marginTop: 30 }}>
            {isSmall && (
              <Button
                variant="default"
                color="dark.4"
                radius="md"
                mr={10}
                onClick={() => navigate('/session/login')}
                sx={{
                  fontSize: '18px',
                  lineHeight: '22px',
                  fontWeight: 400,
                  height: '50px',
                  borderColor: 'white',
                }}
              >
                {t('authentication.sign-in')}
              </Button>
            )}

            <Button
              color="custom-primary-color.0"
              radius="md"
              sx={{
                fontSize: '18px',
                lineHeight: '22px',
                color: 'white',
                fontWeight: 400,
                height: '50px',
              }}
              type="submit"
              loading={isLoading}
            >
              <Text sx={{ fontSize: '16px', lineHeight: '22px', fontWeight: 400 }}>{t('authentication.reset-password')}</Text>
            </Button>
          </Group>
        </form>
      </Box>
    </Box>
  )
}
