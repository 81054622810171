import { ActionIcon, Box, Button, ButtonProps, Flex, Text } from '@mantine/core'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { VisitorTile } from './visitor-tile'
import { TextInput } from 'components/input/text-input'
import { useTranslation } from 'react-i18next'
import { useTableStyles } from 'components/table/table.styles'
import { useDebouncedState } from '@mantine/hooks'
import { IconSearch, IconTableExport } from '@tabler/icons-react'
import { Visitor } from 'api/dto/visitor'
import { exportToCsv } from 'utils/export'
import { deleteExtraExportedFields } from 'utils/export-fields'
import { Relation } from 'api/dto/relation'
import { EditMyRelation } from 'components/modal/exhibitor/edit.my-relation'
import { DeleteMyRelation } from 'components/modal/exhibitor/delete.my-relation'

interface VisitorTileListProps {
  tradeshowId?: string
  exhibitorId?: string
  visitors: Visitor[]
  searchable?: boolean
  actionIcons?: ReactNode[]
  exportList?: {
    filename: string
  }
}

export function VisitorTileList({ visitors, actionIcons, exportList, exhibitorId, tradeshowId }: VisitorTileListProps) {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const [editRelation, setEditRelation] = useState<Relation | undefined>()
  const [deleteRelation, setDeleteRelation] = useState<Relation | undefined>()
  const items = useMemo(
    () =>
      visitors.filter((item) => {
        for (const value of Object.values(item)) {
          if (typeof value === 'string' && value.toLowerCase().includes(search)) {
            return true
          }

          if (typeof value === 'number' && value.toString().includes(search)) {
            return true
          }
        }

        return false
      }),
    [search, visitors],
  )

  const ExportButton = () => {
    return (
      <Button
        size="md"
        w="100%"
        variant="filled"
        color="custom-primary-color.0"
        disabled={items.length === 0}
        leftIcon={<IconTableExport />}
        onClick={() =>
          exportToCsv(
            items.map((row) => {
              const currentRow: any = { ...row }
              return deleteExtraExportedFields(currentRow, true)
            }),
            `${exportList?.filename}.csv`,
          )
        }
      >
        {t('common.export')}
      </Button>
    )
  }

  return (
    <Box display="flex" sx={{ flexDirection: 'column', gap: '1rem', alignItems: 'center', width: '90%' }}>
      <Flex w="100%" gap={10} direction="row" align="center">
        {exportList && <ExportButton />}
        {actionIcons}
      </Flex>
      <GlobalTileFilter items={items} globalFilter={search} setGlobalFilter={setSearch} />
      {items.map((item, index) => (
        <VisitorTile
          key={index}
          item={item}
          editRelation={(relation) => setEditRelation(relation)}
          deleteRelation={(relation) => setDeleteRelation(relation)}
        />
      ))}
      <EditMyRelation
        tradeShowId={tradeshowId}
        exhibitorId={exhibitorId}
        opened={!!editRelation}
        onClose={() => setEditRelation(undefined)}
        relation={editRelation}
      />
      <DeleteMyRelation opened={!!deleteRelation} onClose={() => setDeleteRelation(undefined)} relation={deleteRelation} />
    </Box>
  )
}

interface FilterProps<T> {
  items: T[]
  globalFilter: string
  setGlobalFilter: (value: string) => void
}

function GlobalTileFilter<T extends object>(props: FilterProps<T>) {
  const { t } = useTranslation()
  const { classes } = useTableStyles({ hasFilters: false })
  const { globalFilter, setGlobalFilter, items } = props
  const count = items.length
  const [value, setValue] = useState(globalFilter)
  const [debounced, setDebouncedVal] = useDebouncedState('', 200, undefined)

  useEffect(() => {
    setGlobalFilter(debounced)
  }, [setGlobalFilter, debounced])

  return (
    <Box w="100%" className={classes.search}>
      <Box className={classes.searchInput}>
        <IconSearch color="#cecece" />
        <TextInput
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value)
            setDebouncedVal(e.target.value)
          }}
          placeholder={t('common.search')!}
        />
      </Box>
      <Box className={classes.result}>
        <Text>{count}</Text>
        <Text ml={3}>{t('common.results')}</Text>
      </Box>
    </Box>
  )
}
