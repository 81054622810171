import { Button, Checkbox, Group, Modal, Select, Text, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { Price, PriceType } from 'api/dto/price'
import { useGlobalPrices, useTradeshowPrices, useUpdatePrice } from 'api/query/price'
import { TextInput } from 'components/input/text-input'
import { Loading } from 'components/loading/loading'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

interface EditPriceProps {
  opened: boolean
  onClose: () => void
  price?: Price
}

export function EditPrice({ opened, onClose, price }: EditPriceProps) {
  const { t } = useTranslation()
  const { tradeshowId } = useParams()
  const form = useForm<Partial<Price>>({
    validate: {
      type: (value) => (value ? null : <Text sx={{ fontSize: '10px' }}>{t('common.field-required')}</Text>),
      nameFr: (value) => (value ? null : <Text sx={{ fontSize: '10px' }}>{t('common.field-required')}</Text>),
      nameEn: (value) => (value ? null : <Text sx={{ fontSize: '10px' }}>{t('common.field-required')}</Text>),
      descriptionFr: (value) => (value ? null : <Text sx={{ fontSize: '10px' }}>{t('common.field-required')}</Text>),
      descriptionEn: (value) => (value ? null : <Text sx={{ fontSize: '10px' }}>{t('common.field-required')}</Text>),
      globalPriceId: (value) => (value ? null : <Text sx={{ fontSize: '10px' }}>{t('common.field-required')}</Text>),
    },
  })
  const { data: prices, isLoading } = useTradeshowPrices(tradeshowId)
  const { mutateAsync: editPrice } = useUpdatePrice()
  const [submitting, setSubmitting] = useState(false)
  const { data: globalPrices, isLoading: isPending, isSuccess } = useGlobalPrices()
  const hasCompanionPrice = prices?.filter((p) => p.id != price?.id).some((price) => price.type === PriceType.COMPANION) || false
  const priceTypes = useMemo(() => {
    if (hasCompanionPrice) {
      return Object.values(PriceType).filter((type) => type !== PriceType.COMPANION)
    }
    return Object.values(PriceType)
  }, [hasCompanionPrice])

  useEffect(() => {
    if (opened) {
      form.setValues({ ...price, isSoldOut: price?.isSoldOut || false })
    } else {
      form.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened, price])

  if (isPending) {
    return <Loading />
  }

  if (!isSuccess) {
    showNotification({
      title: t('common.error.title'),
      message: t('common.error.message'),
      color: 'red',
    })

    // TODO: redirect 500
    return null
  }

  async function onSubmit(values: Partial<Price>) {
    setSubmitting(true)
    await editPrice({ tradeshowId: tradeshowId!, price: values })
    setSubmitting(false)
    onClose()
  }

  return (
    <Modal size="lg" mih={400} opened={opened} onClose={onClose} title={t('price.edit')}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextInput withAsterisk size="sm" label={t('price.nameEn')} placeholder={t('price.nameEn')} {...form.getInputProps('nameEn')} />
        <TextInput
          withAsterisk
          mt={5}
          size="sm"
          label={t('price.nameFr')}
          placeholder={t('price.nameFr')}
          {...form.getInputProps('nameFr')}
        />
        <Textarea
          withAsterisk
          mt={5}
          size="sm"
          rows={3}
          label={t('price.descriptionEn')}
          placeholder={t('price.descriptionEn')}
          {...form.getInputProps('descriptionEn')}
        />
        <Textarea
          withAsterisk
          mt={5}
          size="sm"
          rows={3}
          label={t('price.descriptionFr')}
          placeholder={t('price.descriptionFr')}
          {...form.getInputProps('descriptionFr')}
        />
        <Select
          mt={5}
          withAsterisk
          label={t('price.priceType')}
          dropdownPosition="bottom"
          data={priceTypes.map((type) => ({ value: type, label: t(`price.type.${type}`)! }))}
          withinPortal={true}
          {...form.getInputProps('type')}
        />

        <Select
          mt={5}
          withAsterisk
          label={t('price.globalPrice')}
          dropdownPosition="bottom"
          data={globalPrices.map((globalPrice) => ({
            value: globalPrice.id,
            label: `${globalPrice.amount} ${globalPrice.currency.toUpperCase()} | ${globalPrice.id} ${
              globalPrice.name ? ' - ' + globalPrice.name : ''
            }`,
          }))}
          withinPortal={true}
          {...form.getInputProps('globalPriceId')}
        />

        <TextInput
          mt={5}
          size="sm"
          label={t('price.waitingListUrl')}
          placeholder={t('price.waitingListUrl')}
          {...form.getInputProps('waitingListUrl')}
        />
        <Checkbox
          mt={20}
          color="custom-primary-color.0"
          label={t('price.active')}
          {...form.getInputProps('isSoldOut')}
          checked={form.values.isSoldOut}
          onChange={(event) => form.setFieldValue('isSoldOut', event.currentTarget.checked)}
        />
        {form.values.type === PriceType.TICKET && (
          <Checkbox
            mt={20}
            color="custom-primary-color.0"
            label={t('price.memberPrice')}
            {...form.getInputProps('isMemberPrice')}
            checked={form.values.isMemberPrice}
            onChange={(event) => form.setFieldValue('isMemberPrice', event.currentTarget.checked)}
          />
        )}
        <Group mt={30} position="right">
          <Button disabled={submitting} variant="outline" size="xs" color="red" onClick={onClose}>
            {t('common.cancel')}
          </Button>

          <Button loading={submitting} disabled={submitting || !form.isValid()} size="xs" variant="outline" color="green" type="submit">
            {t('common.update')}
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
